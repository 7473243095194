export var language = {
    'welcome': 'Bun venit',
    'register': 'Inregistrare',
    'login': 'Autentificare',
    'discount_code': 'Cod de discount',
    'sign_in_message': 'Conectați-vă',
    'sign_in': 'Conectare',
    'forgot_password' : 'Parola uitata',
    'create_new_account' : 'Create cont nou',
    'create_your_account': 'Creeaza propriul cont',
    'romanian': 'Romana',
    'english': 'Engleza',
    'password': 'Parola',
    'confirm_password': 'Confirma parola',
    'terms_and_conditions': 'Termenii si conditiile',
    'agree': 'Sunt de acord cu',
    'not_robot': 'Nu sunt robot',
    'create_account': 'Creare cont',
    'login_into_account': 'Autentificare in cont',
    'and': 'si',
    'privacy_policy': 'Politica de confidentialitate',
    'profile': 'Profil',
    'profile_information': 'Informatii despre profil',
    'company_informations': 'Informatii despre companie',
    'country': 'Tara',
    'company_name': 'Nume companie',
    'registration_code': 'Cod de inregistrare',
    'vat_number': 'Numar TVA',
    'trade_register_number': 'Numar registrul comertului',
    'office_address': 'Adresa birou',
    'county': 'Judet',
    'local': 'Localitate',
    'address': 'Adresa',
    'contact_person': 'Persoana de contact',
    'first_name': 'Nume',
    'last_name': 'Prenume',
    'phone_number': 'Numar de telefon',
    'shop_url': 'URL Magazin',
    'city': 'Oras',
    'currency': 'Moneda',
    'product_stock_sync': 'Interval sincronizare stoc produse (ore)',
    'orders' : 'Comenzi',
    'orders_feed' : 'Sursa Comenzi', 
    'import_orders' : 'Incarca comenzi',
    'OrderID' : 'ID Comanda',
    'pieces': 'Buc',
    'order_product_price': 'Pret',
    'Product' : 'Produs',
    'Quantity' : 'Cantitate',
    'Date' : 'Data',
    'orders_table': 'Tabel comenzi',
    'products': 'Produse',
    'product_feed_url': 'URL Sursa Produse',
    'product_feeds': 'Surse Produse',
    'product_feed': 'URL Sursa Produse',
    'products_table': 'Tabel produse',
    'identifier': 'Identificator',
    'picture': 'Fotografie',
    'name': 'Nume',
    'category': 'Categorie',
    'subcategory': 'Subcategorie',
    'subtype': 'Subtip',
    'price': 'Pret',
    'discount': 'Discount',
    'stock_status': 'Status Stoc',
    'description': 'Descriere',
    'rating': 'Rating',
    'product_url': 'URL Produs',
    'company_url': 'URL Shop',
    'configuration': 'Configurare',
    'choose_color': 'Alege culoare',
    'upload_icon': 'Incarca pictograma',
    'layout': 'Aspect',
    'position': 'Pozitie',
    'left': 'Stanga',
    'right': 'Dreapta',
    'upload': 'Incarca',
    'popup_content': 'Continut Pop-up',
    'add_marketing_messages': 'Adaugare mesaje de marketing si discount-uri',
    'see_data': 'Vezi date',
    'popup_display_frequency': 'Frecventa aparitie zilnica pop-up',
    'new_guests': 'Vizitatori noi',
    'visits': 'Vizite',
    'actions_per_visit': 'Actiuni per vizita',
    'average_visit_duration': 'Timp mediu vizita',
    'cart_views': 'Vizualizari cos',
    'delivered_popups': 'Popup-uri trimise',
    'dismissed_popups': 'Popup-uri inchise',
    'accessed_products': 'Produse accesate',
    'discount_views': 'Vizualizari discount',
    'since_last_month': 'Fata de luna trecuta',
    'top_products': 'Top produse',
    'number_of_visits': 'Numar vizite',
    'save': 'Salvare',
    'done': 'Done',
    'help': 'Iti venim in ajutor cu aceste video-uri',
    'product_link': 'Link produs',
    'delete': 'Sterge',
    'intro_messages': 'Mesaje de introducere',
    'select_all': 'Selecteaza tot',
    'messages_discounts': 'Mesaje si discount-uri',
    'discount_messages': 'Mesaje pentru discount-uri',
    'no_products_message': 'Momentan nu exista produse.',
    'products_and_recommendations': 'Produse si recomandari pentru fiecare utilizator',
    'no_recommendations': 'Momentan nu exista recomandari de produse.',
    'viewed_product': 'Produs vizualizat',
    'recommended_products': 'Produse recomandate',
    'accessed_recommendation': 'Recomandare accesata',
    'no_discounts': 'Momentan nu exista discount-uri',
    'recommended_discounts': 'Discount-uri recomandate',
    'no_checkouts': 'Momentan nu exista dicount-uri.',
    'number_of_checkouts': 'Numar checkout-uri',
    'subscription': 'Abonament',
    'invoice_history': 'Istoric facturi',
    'period': 'Perioada',
    'sum': 'Suma',
    'actions': 'Actiuni',
    'download_invoice': 'Descarca factura',
    'users': 'Utilizatori',
    'complete_user_details': 'Completeaza detaliile noului utilizator',
    'add_user': 'Adauga utilizator',
    'edit_user_details': 'Editeaza detaliile utilizatorului',
    'delete_admin_conf': 'Esti sigur ca vrei sa stergi acest admin?',
    'yes': 'Da',
    'no': 'Nu',
    'view_users': 'VIZUALIZARE UTILIZATORI',
    'no_users': 'Nu exista utilizatori momentan.',
    'edit': 'Editeaza',
    'installation': 'Instalare',
    'step_1': 'Pasul 1',
    'step_2': 'Pasul 2',
    'step_3': 'Pasul 3',
    'step_4': 'Pasul 4',
    'step_5': 'Pasul 5',
    'step_1_text': 'Introdu link-ul catre produsele din shop-ul tau (Ex: myshop.com/product)',
    'step_2_text': 'Introdu link-ul paginii cosului de cumparaturi din shop-ul tau (Ex: myshop.com/cart)',
    'step_3_text': 'Introdu link-ul paginii de check-out din cosul tau (Ex: myshop.com/checkout)',
    'step_4_text': 'Introdu link-ul paginii de comanda realizata din shop-ul tau (Ex: myshop.com/successful-order)',
    'step_5_text': 'Insereaza urmatorul fragment de cod in radacina proiectului',
    'feed_url': 'Products Feed URL',
    'cart_url': 'URL Cos Cumparaturi',
    'checkout_url': 'URL Checkout',
    'success_order_url': 'URL comanda realizata',
    'user_profile': 'Profilul utilizatorului',
    'settings': 'Setari',
    'reports': 'Rapoarte',
    'pay': 'Plati',
    'installer': 'Instalare',
    'logout': 'Deconectare',
    'change_client': 'Schimbare client',
    'user_fingerprint': 'Amprenta utilizator',
    'choose_client': 'Alege un client',
    'view_dashboard': 'Vizualizare dashboard',
    'shop_name': 'Nume magazin',
    'check_email': 'Te rugam sa verifici email-ul!',
    'reset_pass_email': 'A fost trimis un email pentru resetarea parolei.',
    'enter_account_email': 'Introdu email-ul contului tau',
    'send_mail_password': 'Trimite email cu noua parola',
    'date': 'Data',
    'product_performance': 'Performanta produselor',
    'history_average': 'Media istorica',
    'current_average': 'Media curenta',
    'product_name': 'Produs',
    'google_merchant_text': 'Putem adauga cu usurinta produsele tale din contul Google Merchant. Doresti sa incepi acest proces?',
    'insert_merchant_id': 'Va rugam sa adaugati merchant id',
    'submit': 'Trimite',
    'cancel': 'Anulare',
    'stores': 'Magazine',
    'stores_list': 'Lista magazine',
    'add_new_store': 'Adauga un magazin nou',
    'add_store': 'Adauga magazin',
    'store_name': 'Nume magazin',
    'location': 'Locatie',
    'select_country': 'Selecteaza tara',
    'select_county': 'Selecteaza judet',
    'select_city': 'Selecteaza oras',
    'choose_store': 'Alege magazin',
    'qr_code': 'Cod QR',
    'are_you_sure': 'Esti sigur?',
    'delete_store_text': 'Aceasta actiune va sterge magazinul impreuna cu produsele sale',
    'success': 'Succes!',
    'store_deleted': 'Magazin sters!',
    'store_updated': 'Magazin actualizat!'
}